<template>
  <v-row>
    <v-col>
      <v-navigation-drawer color="primary" fixed absolute dark left>
        <v-list dense>
          <v-subheader>Résultats</v-subheader>
          <!-- lien menu seul -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Temps</v-list-item-title>
              {{ time || '0' }}{{ time ? 's':'' }}
            </v-list-item-content>
          </v-list-item>
          <!-- fin lien menu seul -->
          <!-- lien menu seul -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Progression
                (dernier atteint :
                {{ previousReachedCircle + 1 > 0 ? previousReachedCircle + 1: '0' }})
              </v-list-item-title>
              <v-progress-linear color="white"
              :value="100 * ((previousReachedCircle + 1) / circles.length)">
            </v-progress-linear>
            <p class="caption">{{ finished ? 'Terminé' : 'Non terminé' }}</p>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->
      </v-list>

      <v-list dense nav class="pt-0">
        <v-subheader>Tracé</v-subheader>
        <!-- lien menu seul -->
        <v-list-item @click="play" :disabled="disableReplay" link>
          <v-list-item-icon>
            <v-icon :disabled="disableReplay">mdi-play</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Rejouer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->
        <!-- lien menu seul -->
        <v-list-item @click="clear" :disabled="disableReplay" link>
          <v-list-item-icon>
            <v-icon :disabled="disableReplay">mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Effacer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->
        <!-- lien menu seul -->
      </v-list>

      <v-list dense nav>
        <v-subheader>Actions</v-subheader>
        <v-list-item link disabled>
          <v-list-item-icon>
            <v-icon>mdi-note</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ajouter une note</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->
        <!-- lien menu seul -->
        <v-list-item @click="save()" :disabled="hasBeenSaved" link>
          <v-list-item-icon>
            <v-icon :disabled="hasBeenSaved">mdi-content-save</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sauvegarder</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->
        <!-- lien menu seul -->
        <v-list-item @click="askBeforeQuit()" link>
          <v-list-item-icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Retour au dossier</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- fin lien menu seul -->
      </v-list>
    </v-navigation-drawer>
  </v-col>
  <v-col>
    <div class="d-flex align-center">
      <canvas ref="stage" style="border: 1px solid #AFAFAF"  width="900px" height="500px"></canvas>
    </div>
  </v-col>
  <v-dialog v-model="dialog.confirmation" max-width="40%" persistent>
    <v-card>
      <v-card-title class="headline">Confirmation</v-card-title>
      <v-card-text>
        <p>Voulez-vous enregistrer les modifications ?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="dialog.confirmation = false;">
          Annuler
        </v-btn>
        <v-btn color="blue" outlined @click="quit()">
          Ne pas enregistrer
        </v-btn>
        <v-btn color="green" outlined @click="saveAndQuit()">
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar bottom color="black" right v-model="snackbar">
    Les derniers résultats ont été bien été chargés
    <v-btn color="white" text @click="snackbar = false">Fermer</v-btn>
  </v-snackbar>
</v-row>
</template>

<script>
import MixinNeurotests from '@/mixins/neurotests';

export default {
  name: 'TestTrailMaking',
  mixins: [MixinNeurotests],
  props: {
    patientId: String,
    neurotestId: String,
  },

  beforeRouteLeave(from, to, next) {
    clearInterval(this.recordIntervalId);
    next();
  },

  async mounted() {
    this.stage = this.$refs.stage.getContext('2d');
    this.$refs.stage.addEventListener('mousemove', this.canvasMouseMove, false);
    this.$refs.stage.addEventListener('mouseup', this.canvasMouseUp, false);
    this.$refs.stage.addEventListener('mousedown', this.canvasMouseDown, false);

    this.circles.forEach((item) => {
      const circle = item;
      /* TODO: utiliser les bonnes positions */
      circle.x *= 4;
      circle.y *= 2.5;
      circle.y -= 220;
      this.drawCircle(circle.label, circle.x, circle.y, 'white');
    });

    const neurotest = await this.getNeurotestInfo(this.neurotestId, this.patientId);
    if (neurotest.result) {
      this.video = neurotest.result.video;
      this.time = neurotest.result.time;
      this.snackbar = true;
    }
  },

  computed: {
    disableReplay() {
      return this.video.length === 0;
    },
  },

  methods: {
    async canvasMouseDown(e) {
      this.drag = true;
      this.startTime = Date.now();
      const fps = await this.getFPS();
      this.previousPos.x = e.offsetX;
      this.previousPos.y = e.offsetY;
      this.recordIntervalId = setInterval(this.record, 1000 / fps);
      this.hasBeenSaved = false;
    },

    canvasMouseUp(e) {
      this.drag = false;
      this.previousPos.x = e.offsetX;
      this.previousPos.y = e.offsetY;
      this.time = Math.floor((Date.now() - this.startTime) / 1000);
      clearInterval(this.recordIntervalId);
    },

    record() {
      this.video.push({ x: this.mouse.x, y: this.mouse.y });
    },

    clear() {
      this.stage.clearRect(0, 0, 900, 500);
      this.video.splice(0, this.video.length);
      this.circles.forEach((item) => {
        const circle = item;
        this.drawCircle(circle.label, circle.x, circle.y, 'white');
        circle.reached = false;
      });

      this.mouseIsInCircle = false;
      this.anErrorOccured = false;
      this.previousPos = { x: 0, y: 0 };
      this.previousReachedCircle = -1;
      this.startTime = 0;
      this.time = 0;
      this.finished = false;
      this.hasBeenSaved = false;
    },

    getFPS() {
      return new Promise((resolve) => requestAnimationFrame(
        (start) => requestAnimationFrame((end) => resolve(1000 / (end - start))),
      ));
    },

    drawNextFrame(previous, current) {
      if (previous.x === 0 && previous.y === 0) {
        return;
      }
      this.stage.beginPath();
      this.stage.strokeStyle = 'red';
      this.stage.moveTo(previous.x, previous.y);
      this.stage.lineTo(current.x, current.y);
      this.stage.stroke();
    },

    async play() {
      this.stage.clearRect(0, 0, 900, 500);

      this.circles.forEach((item) => {
        this.drawCircle(item.label, item.x, item.y, 'white');
      });

      let i = 1;
      const fps = await this.getFPS();
      const myInterval = setInterval(() => {
        this.drawNextFrame(this.video[i - 1], this.video[i]);
        // ************************
        this.circles.forEach((item) => {
          const circle = item;

          const distanceToCircle = Math.hypot(this.video[i].x - circle.x,
            this.video[i].y - circle.y);

          this.mouseIsInCircle = (distanceToCircle < this.radius);

          if (this.mouseIsInCircle) {
            this.drawCircle(circle.label, circle.x, circle.y, '#B2DFDB');
          }
        });
        // ************************

        i += 1;
        if (i === this.video.length) {
          // We've played all frames.
          clearInterval(myInterval);
        }
      }, 1000 / fps);
    },

    canvasMouseMove(e) {
      if (!this.drag || this.finished) return;

      this.mouse.x = e.offsetX;
      this.mouse.y = e.offsetY;
      // setTimeout(this.record, 200, { x: e.offsetX, y: e.offsetY });
      this.time = Math.floor((Date.now() - this.startTime) / 1000);
      this.finished = this.testIsFinished();

      // this.circles.forEach((item, index) => {
      let index = 0;

      for (index = 0; index < this.circles.length; index += 1) {
        const circle = this.circles[index];
        const distanceToCircle = Math.hypot(e.offsetX - circle.x, e.offsetY - circle.y);

        this.mouseIsInCircle = (distanceToCircle < this.radius); // radius - 5px

        if (this.mouseIsInCircle) {
          if (!circle.reached && index !== (this.previousReachedCircle + 1)) {
            this.anErrorOccured = true;
          } else {
            this.previousReachedCircle = index;
            circle.reached = true;
          }
        }

        this.drawCircle(circle.label, circle.x, circle.y, circle.reached ? '#B2DFDB' : 'white');

        if (this.previousPos.x !== 0 && this.previousPos.y !== 0) {
          this.stage.beginPath();
          this.stage.moveTo(this.previousPos.x, this.previousPos.y);
          this.stage.lineTo(e.offsetX, e.offsetY);
          this.stage.stroke();
        }
        this.previousPos.x = e.offsetX;
        this.previousPos.y = e.offsetY;
        // });
      }
    },

    testIsFinished() {
      return this.circles.filter((item) => (item.reached === false)).length === 0;
    },

    drawCircle(text, x, y, color = 'white') {
      this.stage.font = 'bold 18pt Arial';
      this.stage.lineWidth = '2';
      this.stage.textBaseline = 'middle';
      this.stage.textAlign = 'center';

      const tmp = this.stage.fillStyle;
      // draw circle
      this.stage.beginPath();
      this.stage.strokeStyle = '#009688';
      this.stage.fillStyle = color;
      this.stage.arc(x, y, this.radius, 0, 2 * Math.PI);
      this.stage.fill();
      this.stage.stroke();
      this.stage.closePath();

      // text
      this.stage.fillStyle = '#009688';

      if (text === '1') this.stage.fillText('Départ', x, y - this.radius * 1.8);
      if (text === this.circles[this.circles.length - 1].label) {
        this.stage.fillText('Fin', x, y - this.radius * 1.8);
      }
      this.stage.fillText(text, x, y);

      this.stage.fillStyle = tmp;
    },

    askBeforeQuit() {
      if (this.hasBeenSaved) {
        this.quit();
      } else {
        this.dialog.confirmation = true;
      }
    },

    async save() {
      const neurotest = await this.getNeurotestInfo(this.neurotestId, this.patientId);
      neurotest.notes = this.notes;
      neurotest.result = { time: this.time, video: this.video };
      this.updateNeurotest(this.neurotestId, this.patientId, neurotest);
      this.hasBeenSaved = true;
    },

    quit() {
      this.$router.push(`/patient/${this.patientId}`);
    },

    async saveAndQuit() {
      await this.save();
      this.quit();
    },
  },
  data: () => ({
    stage: {},
    snackbar: false,
    mouseIsInCircle: false,
    video: [],
    dialog: { confirmation: false },
    hasBeenSaved: true,
    anErrorOccured: false,
    previousPos: { x: 0, y: 0 },
    previousReachedCircle: -1,
    /* eslint object-curly-newline: ["error", { "multiline": true, "minProperties": 5 }] */
    circles: [{ x: 135, y: 196, label: '1', reached: false },
      { x: 102, y: 227, label: '2', reached: false },
      { x: 156, y: 229, label: '3', reached: false },
      { x: 160, y: 104, label: '4', reached: false },
      { x: 100, y: 135, label: '5', reached: false },
      { x: 128, y: 162, label: '6', reached: false },
      { x: 89, y: 179, label: '7', reached: false },
      { x: 40, y: 224, label: '8', reached: false },
      { x: 51, y: 249, label: '9', reached: false },
      { x: 75, y: 224, label: '10', reached: false },
      { x: 99, y: 257, label: '11', reached: false },
      { x: 15, y: 259, label: '12', reached: false },
      { x: 29, y: 145, label: '13', reached: false },
      /* { x: 11, y: 169, label: '14', reached: false },
      { x: 12, y: 34, label: '15', reached: false },
      { x: 33, y: 80, label: '16', reached: false },
      { x: 72, y: 16, label: '17', reached: false },
      { x: 86, y: 79, label: '18', reached: false },
      { x: 139, y: 59, label: '19', reached: false },
      { x: 100, y: 55, label: '20', reached: false },
      { x: 114, y: 14, label: '21', reached: false },
      { x: 198, y: 42, label: '22', reached: false },
      { x: 205, y: 250, label: '23', reached: false },
      { x: 180, y: 142, label: '24', reached: false },
      { x: 173, y: 257, label: '25', reached: false }, */
    ],
    startTime: 0,
    time: 0,
    finished: false,
    radius: 25,
    drag: false,
    notes: '',
    recordIntervalId: 0,
    mouse: { x: 0, y: 0 },
  }),
};

</script>
